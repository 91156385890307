.orders-wrapper {
  border-top: 2px solid var(--ion-color-primary);
}

.cards, .list, .order-groups {
  height: 70vh;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

.info {
  height: 70vh;
}

.refresh-wrapper {
  position: relative;
}

.number-badge {
  background: var(--ion-color-primary);
  color: white;
  padding: .3rem;
  border-radius: 999px;
  font-size: 1rem;
  line-height: .75;

  min-width: 1.25rem;
  text-align: center;

  position: absolute;
  top: 0;
  right: 0;
  transform: translate(65%, -20%);
}

.create-order-btn {
  border: 2px solid var(--ion-color-primary);
  border-top: none;
  cursor: pointer;

  &__plus-col {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    line-height: 1rem;
    color: white;
    background: var(--ion-color-primary);
  }

  &__text-col {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    line-height: 1rem;
    background: white;
    color: var(--ion-color-primary);
  }
}
