// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-color-copper: #D97D54;
  --ion-color-copper-rgb: 217, 125, 84;
  --ion-color-copper-contrast: #000000;
  --ion-color-copper-contrast-rgb: 0, 0, 0;
  --ion-color-copper-shade: #bf6e4a;
  --ion-color-copper-tint: #dd8a65;

  --ion-color-primary: var(--ion-color-copper);
  --ion-color-primary-rgb: var(--ion-color-copper-rgb);
  --ion-color-primary-contrast: var(--ion-color-copper-contrast);
  --ion-color-primary-contrast-rgb: var(--ion-color-copper-contrast-rgb);
  --ion-color-primary-shade: var(--ion-color-copper-shade);
  --ion-color-primary-tint: var(--ion-color-copper-tint);

  --ion-color-cambridge: #9ABDB1;
  --ion-color-cambridge-rgb: 154, 189, 177;
  --ion-color-cambridge-contrast: #000000;
  --ion-color-cambridge-contrast-rgb: 0, 0, 0;
  --ion-color-cambridge-shade: #88a69c;
  --ion-color-cambridge-tint: #a4c4b9;

  --ion-color-charcoal: #324755;
  --ion-color-charcoal-rgb: 50, 71, 85;
  --ion-color-charcoal-contrast: #ffffff;
  --ion-color-charcoal-contrast-rgb: 255, 255, 255;
  --ion-color-charcoal-shade: #2c3e4b;
  --ion-color-charcoal-tint: #475966;

  --ion-color-eggplant: #70505B;
  --ion-color-eggplant-rgb: 112,80,91;
  --ion-color-eggplant-contrast: #ffffff;
  --ion-color-eggplant-contrast-rgb: 255,255,255;
  --ion-color-eggplant-shade: #634650;
  --ion-color-eggplant-tint: #7e626b;

  --ion-color-earth: #D9A75F;
  --ion-color-earth-rgb: 217,167,95;
  --ion-color-earth-contrast: #000000;
  --ion-color-earth-contrast-rgb: 0,0,0;
  --ion-color-earth-shade: #bf9354;
  --ion-color-earth-tint: #ddb06f;

  --ion-color-success: #69bb7b;
  --ion-color-success-rgb: 105,187,123;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #5ca56c;
  --ion-color-success-tint: #78c288;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-dark: #1B1C20;
  --ion-color-dark-rgb: 27, 28, 32;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #18191c;
  --ion-color-dark-tint: #323336;

  --ion-color-background: #F5F5FA;
  --ion-color-background-rgb: 245, 245, 250;
  --ion-color-background-contrast: #000000;
  --ion-color-background-contrast-rgb: 0, 0, 0;
  --ion-color-background-shade: #d8d8dc;
  --ion-color-background-tint: #f6f6fb;

  --ion-background-color: var(--ion-color-dark-shade);
  --ion-toolbar-background: var(--ion-color-dark);
  --ion-toolbar-border-color: var(--ion-color-dark-tint);

  --ion-card-border-radius: 1rem;
  --ion-transition-duration: .15s;

  --ion-font-family: 'Poppins', sans-serif;
}

body {
  --ion-background-color: var(--ion-color-background);
  --ion-background-color-rgb: var(--ion-color-background-rgb);

  --ion-text-color: var(--ion-color-dark);
  --ion-text-color-rgb: 255, 255, 255;

  --ion-item-background: #000000;

  --ion-card-background: #1c1c1d;
}

.ion-color-copper {
  --ion-color-base: var(--ion-color-copper);
  --ion-color-base-rgb: var(--ion-color-copper-rgb);
  --ion-color-contrast: var(--ion-color-copper-contrast);
  --ion-color-contrast-rgb: var(--ion-color-copper-contrast-rgb);
  --ion-color-shade: var(--ion-color-copper-shade);
  --ion-color-tint: var(--ion-color-copper-tint);
}

.ion-color-charcoal {
  --ion-color-base: var(--ion-color-charcoal);
  --ion-color-base-rgb: var(--ion-color-charcoal-rgb);
  --ion-color-contrast: var(--ion-color-charcoal-contrast);
  --ion-color-contrast-rgb: var(--ion-color-charcoal-contrast-rgb);
  --ion-color-shade: var(--ion-color-charcoal-shade);
  --ion-color-tint: var(--ion-color-charcoal-tint);
}

.ion-color-cambridge {
  --ion-color-base: var(--ion-color-cambridge);
  --ion-color-base-rgb: var(--ion-color-cambridge-rgb);
  --ion-color-contrast: var(--ion-color-cambridge-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cambridge-contrast-rgb);
  --ion-color-shade: var(--ion-color-cambridge-shade);
  --ion-color-tint: var(--ion-color-cambridge-tint);
}

.ion-color-eggplant {
  --ion-color-base: var(--ion-color-eggplant);
  --ion-color-base-rgb: var(--ion-color-eggplant-rgb);
  --ion-color-contrast: var(--ion-color-eggplant-contrast);
  --ion-color-contrast-rgb: var(--ion-color-eggplant-contrast-rgb);
  --ion-color-shade: var(--ion-color-eggplant-shade);
  --ion-color-tint: var(--ion-color-eggplant-tint);
}

.ion-color-earth {
  --ion-color-base: var(--ion-color-earth);
  --ion-color-base-rgb: var(--ion-color-earth-rgb);
  --ion-color-contrast: var(--ion-color-earth-contrast);
  --ion-color-contrast-rgb: var(--ion-color-earth-contrast-rgb);
  --ion-color-shade: var(--ion-color-earth-shade);
  --ion-color-tint: var(--ion-color-earth-tint);
}

.ion-color-dark {
  --ion-color-base: var(--ion-color-dark);
  --ion-color-base-rgb: var(--ion-color-dark-rgb);
  --ion-color-contrast: var(--ion-color-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-dark-shade);
  --ion-color-tint: var(--ion-color-dark-tint);
}

.ion-color-background {
  --ion-color-base: var(--ion-color-background);
  --ion-color-base-rgb: var(--ion-color-background-rgb);
  --ion-color-contrast: var(--ion-color-background-contrast);
  --ion-color-contrast-rgb: var(--ion-color-background-contrast-rgb);
  --ion-color-shade: var(--ion-color-background-shade);
  --ion-color-tint: var(--ion-color-background-tint);
}

$fa-font-path: '../../assets/fonts/fontawesome';
