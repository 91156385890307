/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/theme";

* {
  font-family: 'Poppins', sans-serif;
}

ion-toolbar {
  ion-buttons {
    ion-button, ion-back-button {
      color: white;

      i {
        color: white;
      }
    }
  }
}

ion-content {
  --background: transparent;
}

.bg {
  --background: var(--ion-background-color) url('assets/img/bg.svg') no-repeat center center / cover;
}

.color-copper {
  color: var(--ion-color-copper);
}

.color-charcoal {
  color: var(--ion-color-charcoal);
}

.color-cambridge {
  color: var(--ion-color-cambridge);
}

.color-earth {
  color: var(--ion-color-earth);
}

.shadow {
  box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, 0.25);
}

.shadow-sm {
  box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, 0.25);
}

.addedToMenuCustomToast {
  --color: #ffffff;
  --border-color: #ffffff;
  --border-radius: 6px;
  --border-style: solid;
  --width: 30%;
  --border-width: 1px;
  --background: var(--ion-color-cambridge);
}


.ios button.alert-button.remove-from-cart-alert-button {
  color: var(--ion-color-cambridge);

}



.ios button.alert-button.collect-menu-alert-button {
  color: var(--ion-color-cambridge);
}

// todo: this is for lunch-day-crud article selection - find a better solution for this
.sc-ion-alert-md-h{
  --min-width: 90vw;
}

[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  border: initial;
  background: initial;
  padding: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}
