ion-button {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 2rem;
  --padding-end: 2rem;
  --border-radius: 999px;
  letter-spacing: 1px;
  font-size: 1rem;
  position: relative;

  &::part(native) {
    --ion-color-contrast: white;

    .button-inner {
      display: block;
    }
  }
}

.icon-button {
  background: white;
  display: inline-block;
  color: var(--ion-color-primary);
  padding: 0 .35rem;
  border-radius: .35rem;
  cursor: pointer;
}
