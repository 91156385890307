.button-bar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100vw;
  z-index: 2;
  background: var(--ion-color-dark);
  opacity: 0;
  transition: all var(--ion-transition-duration) ease-in-out;

  &--show {
    opacity: 1;
  }
}
