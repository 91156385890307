.login {
  background: white;
  color: var(--ion-color-dark);
  border: 2px solid var(--ion-color-dark);

  &__header {
    overflow: auto;
    background: var(--ion-color-dark);

    h3 {
      color: white !important;
    }
  }
}
