.orderGroup {
  position: relative;
  cursor: pointer;
  background: white;

  &__number-col {
    color: white;
    background: var(--ion-color-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__number {
      transform: rotate(180deg);
      writing-mode: vertical-rl;
      font-size: 1.5rem;
    }
  }

  &__main-col {
    color: var(--ion-color-dark);
    display: flex;
    align-items: center;

    p {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }

  &__btn-col {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
