.info-box {
  max-height: 100%;
  overflow-y: scroll;
  background: white;
  color: var(--ion-color-dark);

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}

hr {
  height: 2px;
  background: var(--ion-color-dark);
}
