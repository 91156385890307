.item {
  --background: white;
  --color: var(--ion-color-dark);
  --inner-color: var(--ion-color-dark);
  --inner-border-width: 0;
}

.list {
  &-md {
    padding: 0;
  }

  &-ios, &-md {
    background: none;
  }
}
