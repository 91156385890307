h1, h2, h3, h4, h5 {
  font-weight: 600;
  margin: .5rem 0;
}

h1 {
  font-size: 35px;
  letter-spacing: 1px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 25px;
  letter-spacing: 1px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

p {
  font-size: 16px;
  margin: 0;
}
