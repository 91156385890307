.card {
  position: relative;
  cursor: pointer;

  &::after {
    background: white;
    height: 100%;
    width: 0;
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    transition: all var(--ion-transition-duration) ease-in-out;
  }

  &--after {
    &::after {
      background: white;
      height: 100%;
      width: 20px;
      position: absolute;
      top: 0;
      right: calc(-1*var(--ion-margin, 16px));
      content: '';
    }
  }

  &__number-col {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &__number {
      transform: rotate(180deg);
      writing-mode: vertical-rl;
      font-size: 1.5rem;
    }

    &__checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__icon {
      font-size: 1.5rem;
    }
  }

  &__info-col {
    background: white;
    color: var(--ion-color-dark);

    p {
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }
}
