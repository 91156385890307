.form-item {
  display: flex;
  height: 100%;

  --border-width: 0;

  ion-label {
    margin: 0;
  }
}

ion-input, ion-textarea {
  --color: var(--ion-color-dark);
  --padding-bottom: 1rem !important;
  caret-color: var(--ion-color-dark);
}

.input-bg {
  --background: var(--ion-background-color) !important;
  background: var(--ion-background-color) !important;
}

.textarea {
  width: 100%;
  color: var(--ion-color-dark);
  border: none;
  resize: none;

  &--border {
    background: white;
    border: 2px solid var(--ion-color-dark);
  }
}

input:focus, textarea:focus, ion-select:focus {
  outline: none;
}

form {
  ion-select {
    //padding: 1rem 1rem 1rem 0;
  }
}

.label {
  &--input {
    font-size: .9rem;
    padding-bottom: 0;
  }

  &--border {
    background: white;
    display: inline-block;
    transform: translateY(50%);

    &__icon {
      transform: scale(1.5);
    }
  }
}

.link {
  font-size: 1rem;
  font-weight: 600;
  color: var(--ion-color-dark);
}

ion-checkbox {
  --background: var(--ion-color-background);
  --border-radius: 0;
  --background-checked: var(--ion-color-background);
  --checkmark-color: var(--ion-color-dark);
  --border-color-checked: transparent;
  --checkmark-width: 3px;
  z-index: 1;
}

.checkbox--border {
  --border-color: var(--ion-color-dark);
  --border-color-checked: var(--ion-color-dark);
  --border-width: 1px;
}

ion-toggle {
  --background: var(--ion-color-light);
}

.error-msg {
  color: var(--ion-color-danger);
  font-size: 0.8rem;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;

  &--pn {
    aspect-ratio: 1;
  }

  &--article {
    aspect-ratio: 4/3;
  }

  .cover-img {
    width: 100%;
    height: 100%;
    padding: .5rem;
    object-fit: cover;
  }
}

.autocompletions {
  position: absolute;
  z-index: 6;
  width: calc(100% - 10px);
  max-height: 250px;
  overflow-y: auto;
  cursor: pointer;
}

.product-img {
  border-radius: 15px;
}
