.nav {
  font-size: 1.25rem;
  height: 56px;

  ion-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__link {
    color: var(--ion-color-dark);
    text-decoration: none;

    &--selected {
      //font-weight: 600;
      text-underline-offset: .5rem;
      text-decoration: underline;
    }
  }

  &__icons {
    font-size: 1.5rem;
    color: var(--ion-color-dark);
    cursor: pointer;
  }
}

.last-refresh {
  color: var(--ion-color-dark);
  display: flex;
  align-items: center;
  justify-content: end;
  gap: .5rem;
  height: 2.875rem;
  font-size: 1rem;
}
