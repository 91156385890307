.table-row {
  padding: .5rem 0 .5rem 1rem;
  color: var(--ion-color-dark);
  display: flex;

  &__head {
    color: white;
    background: var(--ion-color-primary);
    position: sticky;
    top: 0;
    z-index: 5;
    display: flex;
    align-items: center;

    &--negative-top {
      top: -1rem;
    }

    &__checkbox {
      display: flex;
      align-items: center;

      ion-checkbox {
        margin-left: .3rem;
      }
    }
  }

  &--border {
    border-bottom: 1px solid var(--ion-color-dark);
  }

  &--bg {
    background: var(--ion-background-color);

    ion-toggle {
      --background: white;
    }
  }

  &--white {
    background: white;
  }

  &--border {
    border-bottom: 1px solid var(--ion-color-dark);
  }

  &--highlighted {
    background: rgba(var(--ion-color-primary-rgb), .2);
  }

  &__number-col {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    margin-top: .25rem;
    cursor: pointer;

    &__number {
      transform: rotate(180deg);
      writing-mode: vertical-rl;
      font-size: 1.25rem;
    }

    &__checkbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      ion-checkbox {
        margin-left: .3rem;
      }
    }

    &__icon {
      font-size: 1.5rem;
      margin-left: .15rem;
    }
  }

  &__img {
    display: block;
    border-radius: 5px;
  }

  &__icon-col {
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 1.5rem;

    i {
      cursor: pointer;
    }
  }
}
