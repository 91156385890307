.admin {
  &__card {
    background: white;
    color: var(--ion-color-dark);

    max-height: 75vh;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
