ion-modal {
  --background: transparent;
  background: transparent;
  --border-radius: 0;
  color: var(--ion-color-dark);
  --box-shadow: none;

  .ion-page {
    width: 100%;
    display: flex;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.modal {
  &__grid {
    width: 100%;
    background: white;

    &-wrapper {
      display: flex;
      box-shadow: 0 .5rem 1rem -.75rem black;
    }
  }


  &--big {
    --width: 75vw;
  }
}

